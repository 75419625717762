import React, { useCallback } from "react";
import { ITheHeaderProfile } from "interfaces";
import Image from "next/image";
import { ThePointsBalance } from "../../../../../components/TheComponents/ThePointsBalance";
import {
  Tile,
  Text,
  MobileView,
  CoinFlex,
  MobileCoins,
  ProfileButton,
  ProfileListItem,
  ProfileMenuHeader,
  Icon,
  Title,
} from "../styles";
import ChakraModal from "components/TheComponents/Modal";
import ExchangeModal from "components/games/ExchangeBanner/ExchangeModal";
import { triggerNotification } from "components/TheComponents/Notification";
import { DateTime } from "luxon";
import { ProfileNavEnum, useProfileContext } from "components/public/ProfileLayout/ProfileContext";
import { useRouter } from "next/navigation";
import { navIconMap } from "components/public/ProfileLayout/components/ProfileNav";
import { MobileHeaderBlock } from "layout/HeaderComponent/styles";
import Dropdown from "../../icons/Dropdown";

const MobileViewProfileMenu = ({
  profile,
  handleToggleMobileDropdown,
}: ITheHeaderProfile & {
  onOpenLogout: () => void;
  handleToggleMobileDropdown: () => void;
}): React.ReactElement => {
  const router = useRouter();
  const [isShowModal, setIsShowModal] = React.useState(false);
  const [currentMonthIsAugust, setCurrentMonthIsAugust] = React.useState(false);
  const { changeActiveTab } = useProfileContext();

  React.useEffect(() => {
    const currentUTCMonth = DateTime.utc().month;
    setCurrentMonthIsAugust(currentUTCMonth === 8); // August is month 8
  }, []);

  const exchange = () => {
    if (profile) {
      setIsShowModal(true);
    } else {
      triggerNotification({ text: "Please, login for exchage points", type: "error" });
    }
  };

  const ProfileMenuItems = [
    ProfileNavEnum.Main,
    ProfileNavEnum.Connections,
    ProfileNavEnum.Raffles,
    ProfileNavEnum.Shop,
    ProfileNavEnum.Bets,
  ];

  const handleMenuItemClick = useCallback(
    (item: ProfileNavEnum) => {
      router.push("/profile");
      changeActiveTab(item);
      handleToggleMobileDropdown();
    },
    [router, changeActiveTab, handleToggleMobileDropdown]
  );

  const handleProfileRediraction = useCallback(() => {
      router.push("/profile");
      handleToggleMobileDropdown();
    },
    [router, handleToggleMobileDropdown]
  );

  return (
    <>
      <MobileView>
        <MobileHeaderBlock as={ProfileMenuHeader}>
          <Title>Your profile</Title>
          <Tile>
            <ProfileButton
              onClick={() => handleProfileRediraction()}
            >
              <Image
                alt="profile"
                src={profile?.profileImageUrl ?? ""}
                width={24}
                height={24}
                style={{
                  borderRadius: "50%",
                }}
              />
              <Text>{profile?.displayName}</Text>
              <Icon>
                <Dropdown fill="#757F97"/>
              </Icon>
            </ProfileButton>
          </Tile>
          <MobileCoins>
            <Tile>
              <CoinFlex>
                <Image alt="w-coin" src="/images/coins/w-coin.svg" width={24} height={24} />
                <Text>
                  <ThePointsBalance value={profile?.pointsBalance} isFixed />
                </Text>
              </CoinFlex>
            </Tile>

            <Tile>
              <CoinFlex>
                <Image alt="g-coin" src="/images/coins/g-coin.svg" width={24} height={24} />
                <Text>
                  <ThePointsBalance value={profile?.gPointsBalance} />
                </Text>
              </CoinFlex>
            </Tile>
          </MobileCoins>
        </MobileHeaderBlock>
        {ProfileMenuItems.map((item, index) => (
          <ProfileListItem key={index} onClick={() => handleMenuItemClick(item)}>
            {navIconMap[item]}
            {item}
          </ProfileListItem>
        ))}
      </MobileView>
      <ChakraModal
        content={
          <ExchangeModal
            onClose={() => {
              setIsShowModal(false);
            }}
          />
        }
        isOpen={isShowModal}
        onClose={() => setIsShowModal(false)}
      />
    </>
  );
};

export default MobileViewProfileMenu;
