import _slicedToArray from "/tmp/build_a69c4e60/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/tmp/build_a69c4e60/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["session"];
var __jsx = React.createElement;
import Head from "next/head";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
import { GlobalStyle } from "components";
import { AppProvider, withApp } from "contexts";
import { Notification } from "components/TheComponents/Notification";
import { CookieBanner } from "components/TheComponents/CookieBanner";
import Script from "next/script";
import StreamFloatingBlock from "components/StreamFloatingBlock";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import RoleAccessProvider from "contexts/RoleAccessProvider";
import { HydrationBoundary, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ShowPageLayoutrProvider from "contexts/ShowPageLayoutContext";
import "styles/root.css";
import "styles/index.css";
import "styles/carousel.css";
import dynamic from "next/dynamic";
var PageLayout = dynamic(function () {
  return import("layout/PageLayout");
}, {
  ssr: false,
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("layout/PageLayout")];
    }
  }
});
var theme = extendTheme({
  styles: {
    global: {
      body: {
        background: "inherit",
        color: "inherit",
        lineHeight: "inherit"
      }
    }
  },
  colors: {
    primary: "#181c2b",
    toxic: {
      500: "#92e02f"
    }
  }
});
var App = function App(_ref) {
  var Component = _ref.Component,
    _ref$pageProps = _ref.pageProps,
    session = _ref$pageProps.session,
    pageProps = _objectWithoutProperties(_ref$pageProps, _excluded);
  var _React$useState = React.useState(function () {
      return new QueryClient({});
    }),
    _React$useState2 = _slicedToArray(_React$useState, 1),
    queryClient = _React$useState2[0];
  // Can be fully remove as is duplicate AppProvider component but in another code style like HOC and its just double wrapped Pages
  // https://stackoverflow.com/questions/56816374/context-consumer-vs-usecontext-to-access-values-passed-by-context-provider
  var ComponentWithApp = withApp(Component);
  React.useEffect(function () {
    if (process.env.NEXT_PUBLIC_HTTPS_FORCE === "true") {
      if (window.location.protocol === "http:") {
        window.open(window.location.href.replace("http://", "https://"), "_self");
      }
    }
  }, []);
  return __jsx(React.Fragment, null, __jsx(Script, {
    strategy: "afterInteractive",
    async: true,
    src: "https://www.googletagmanager.com/gtag/js?id=".concat(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUEREMENT_ID)
  }), __jsx(Script, {
    id: "google-tag",
    strategy: "afterInteractive",
    dangerouslySetInnerHTML: {
      __html: "\n              window.dataLayer = window.dataLayer || [];\n              function gtag(){dataLayer.push(arguments);}\n              gtag('js', new Date());\n              gtag('config', '".concat(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUEREMENT_ID, "');\n            ")
    }
  }), __jsx(Head, null, __jsx("meta", {
    name: "viewport",
    content: "width=device-width, initial-scale=1.0"
  }), __jsx("link", {
    rel: "shortcut icon",
    href: "/favicon/favicon.ico"
  }), __jsx("link", {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/favicon/apple-touch-icon.png"
  }), __jsx("link", {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicon/favicon-32x32.png"
  }), __jsx("link", {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicon/favicon-16x16.png"
  }), __jsx("link", {
    rel: "mask-icon",
    href: "/favicon/favicon.svg",
    color: "#5bbad5"
  }), __jsx("link", {
    rel: "manifest",
    href: "/favicon/site.webmanifest"
  }), __jsx("link", {
    rel: "stylesheet",
    href: "https://use.typekit.net/nxd8hle.css"
  }), __jsx("meta", {
    property: "og:type",
    content: "article"
  }), __jsx("meta", {
    property: "og:site_name",
    content: "Wrewards"
  }), __jsx("meta", {
    property: "og:title",
    content: "WRewards"
  }), __jsx("meta", {
    name: "og:description",
    content: "Wrewards.com - gambling experiences with exciting rewards! Engage in raffles, participate in the leaderboards, and enjoy unique bonuses. Our users indulge in exclusive rewards across various gaming platforms. Seize the opportunity to win valuable prizes with Wrewards.com"
  }), __jsx("meta", {
    property: "og:image:type",
    content: "image/png"
  }), __jsx("meta", {
    property: "og:image:width",
    content: "831"
  }), __jsx("meta", {
    property: "og:image:height",
    content: "467"
  }), __jsx("meta", {
    property: "og:image",
    content: "https://www.wrewards.com/images/logos/preview-link-logo.png"
  }), __jsx("meta", {
    property: "og:image:alt",
    content: "logo"
  }), __jsx("meta", {
    property: "og:url",
    content: "https://www.wrewards.com/"
  }), __jsx("title", null, "WRewards"), __jsx("meta", {
    name: "description",
    content: "Wrewards.com - gambling experiences with exciting rewards! Engage in raffles, participate in the leaderboards, and enjoy unique bonuses. Our users indulge in exclusive rewards across various gaming platforms. Seize the opportunity to win valuable prizes with Wrewards.com"
  }), __jsx("meta", {
    name: "keywords",
    content: "gamble, gambling, wrewards, raffles, leaderboards, joy, binuses, gaming,prizes"
  })), __jsx(QueryClientProvider, {
    client: queryClient
  }, __jsx(HydrationBoundary, {
    state: pageProps.dehydratedState
  }, __jsx(AppProvider, null, __jsx(RoleAccessProvider, null, __jsx(ChakraProvider, {
    theme: theme,
    resetCSS: false
  }, __jsx(GlobalStyle, null), __jsx(ShowPageLayoutrProvider, null, __jsx(PageLayout, null, __jsx(AppBox, null, __jsx(ComponentWithApp, pageProps), __jsx(Notification, null), __jsx(CookieBanner, null), __jsx(StreamFloatingBlock, null)))))))), __jsx(ReactQueryDevtools, null)));
};
export default App;
var AppBox = styled.div.withConfig({
  displayName: "_app__AppBox",
  componentId: "sc-1ncvfrt-0"
})(["position:relative;"]);