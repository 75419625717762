import Image from "next/image";
import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, Ident, IStyles } from "components";
import { TheLeaderboardSourceKeys } from "interfaces";

const defaultLeaderboardOptions = [
  {
    key: TheLeaderboardSourceKeys.gamdom,
    src: "/images/TheLeaderboardHeader/gamdom.svg",
    width: 105,
    height: 52,
  },
  {
    key: TheLeaderboardSourceKeys.packdraw,
    src: "/images/AllBonuses/provider-logos/packdraw.svg",
    width: 131,
    height: 60,
    style: {
      position: "relative",
      top: 2,
    },
  },

  {
    key: TheLeaderboardSourceKeys.clash,
    src: "/images/AllBonuses/provider-logos/clash.svg",
    width: 120,
    height: 36,
    style: {
      position: "relative",
      top: 2,
    },
  },
  {
    key: TheLeaderboardSourceKeys.cases,
    src: "/images/AllBonuses/provider-logos/cases.svg",
    width: 100,
    height: 40,
  },
  {
    key: TheLeaderboardSourceKeys.rain_gg,
    src: "/images/AllBonuses/provider-logos/rain.png",
    width: 100,
    height: 30,
    style: {
      position: "relative",
      top: -3,
    },
  },
  {
    key: TheLeaderboardSourceKeys.wrewards,
    src: "/images/AllBonuses/provider-logos/wrewards.svg",
    width: 121,
    height: 52,
  },
];

export interface Option {
  key: string;
  src?: string;
  width?: number;
  height?: number;
  text?: string;
}
export interface ISlideSwitcher extends IStyles {
  title?: JSX.Element | string;
  value: string;
  onChange: (value: string) => void;
  centerSwitcher?: boolean;
  options?: Option[];
  isGridView?: boolean;
}

export const SlideSwitcher: React.FC<ISlideSwitcher> = ({
  title,
  value = TheLeaderboardSourceKeys.gamdom,
  onChange,
  centerSwitcher = false,
  options = [],
  styles,
  isGridView,
}): JSX.Element => {
  const [switcher, setSwitcher] = React.useState<string>(value);

  const onClick = (value: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    setSwitcher(value);
    onChange && onChange(value);
  };

  return (
    <Box
      isGridView={isGridView}
      styles={
        !centerSwitcher && process.env.NEXT_PUBLIC_SHOW_PACKDRAW_CASINO === "false"
          ? css`
              ${styles}
            `
          : css`
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              ${styles}
            `
      }
    >
      {typeof title !== "undefined" ? <Title>{title}</Title> : null}
      {typeof title !== "undefined" ? <Ident height={16} /> : null}
      <List isGridView={isGridView}>
        {options.length
          ? options.map(({ key, text }) => {
              return (
                <Item key={key} isActive={key === switcher} onClick={onClick(key)}>
                  <SwitcherText>{text}</SwitcherText>
                </Item>
              );
            })
          : defaultLeaderboardOptions.map(({ key, src, width, height, style }) => {
              return (
                <Item key={key} isActive={key === switcher} onClick={onClick(key)}>
                  {/* @ts-ignore */}
                  <Image
                    src={src}
                    width={width}
                    height={height}
                    alt={key}
                    {...(!!style ? { style } : {})}
                  />
                </Item>
              );
            })}
      </List>
    </Box>
  );
};

const Box = styled.div<IStyles & { isGridView?: boolean }>`
  box-sizing: border-box;
  display: inline-block;

  ${({ isGridView }) =>
    isGridView
      ? css`
          max-width: max-content;
          margin: initial;
        `
      : css`
          max-width: min-content;
          margin: 0 auto;
        `}
  ${({ styles }) => styles}
`;

const SwitcherText = styled.div`
  font-family: Roboto;
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-align: center;

  ${$stylesReset}
`;
const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const Title = styled(Text)`
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
`;

const List = styled.div<IStyles & { isGridView?: boolean }>`
  --gap: 0px;
  --height: 50px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--gap);
  padding: 0.25rem;
  background: #0b101b;
  border: 0.5px solid #1b2035;
  border-radius: 10px;
  @media (max-width: calc( 480px - 1px )) {
    --gap: 2px;
    --height: 48px;
  }

  ${({ isGridView }) =>
    isGridView
      ? css`
          height: auto;
          flex-wrap: wrap;
          gap: 4px;

          & > label {
            flex-grow: 1;
            max-width: 50%;
            border-radius: 6px;
          }
        `
      : css`
          @media (max-width: calc( 800px )) {
            height: auto;
            flex-wrap: wrap;
            gap: 4px;

            & > label {
              flex-grow: 1;
              max-width: 100%;
              border-radius: 6px;
            }
          }
        `}
  ${({ styles }) => styles}
`;

const Item = styled.label<IStyles & { isActive?: boolean }>`
  --height: 56px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: var(--height);
  width: calc(var(--height) * 3.03);
  min-width: 134px;
  border: 1px solid transparent;

  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  cursor: pointer;
  ${({ isActive }) =>
    isActive
      ? css`
          background-color: #0d172b;
          border-color: rgba(59, 139, 233, 0.5);
          &:hover {
            border-color: rgba(59, 139, 233, 0.5);
          }
        `
      : css`
          &:hover {
            border-color: rgba(59, 139, 233, 0.2);
          }
        `}
  transition: background-color 0.3s ease-in-out, border-color 0.5s ease-in-out;

  @media (max-width: calc( 480px - 1px )) {
    --gap: 2px;
    --height: 48px;
  }
  ${({ styles }) => styles}
`;
