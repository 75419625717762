import React, { FC, memo, useContext } from 'react';
import styled from 'styled-components';
import Chat from './Chat';
import ChatIcon from 'icons/ChatIcon';
import { useShowPageLayoutContext } from 'contexts/ShowPageLayoutContext';

interface ChatSidebarProps {
    show?: boolean;
}

const ChatSidebar: FC<ChatSidebarProps> = ({ show }) => {
    const { showChatSidebar, setShowChatSidebar } = useShowPageLayoutContext();

    const handleToggleChatSidebar = () => {
        setShowChatSidebar(prev => !prev);
    };

    return (
        <Sidebar show={show}>
            <ChatToggleBtn onClick={handleToggleChatSidebar}>
               <ChatIcon />
            </ChatToggleBtn>

            <Chat />
        </Sidebar>
    );
};

const Sidebar = styled.aside<ChatSidebarProps>`
    height: 100%;
    width: 100%;
    position: relative;
    background-color: var(--global-bg-dark-1);
    width: var(--sidebar-width);
    height: calc(100vh - var(--header-height));
    top: calc(var(--header-height) + 1px);
    right: 0;
    position: fixed;
    z-index: 7;
    transform: ${({ show }) => (show ? "translateX(0)" : "translateX(100%)")};
    transition: transform 0.3s;

    @media (max-width: 500px) {
        height: calc(100vh - var(--mobile-header-height));
        top: calc(var(--mobile-header-height) + 1px);
    }
`;

const ChatToggleBtn = styled.div`
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 14px;
    background-color: var(--global-bg-dark-2);
    border-radius: 0px 0px 0px 8px;
    cursor: pointer;
    transform: translateX(-100%);
    margin-left: -1px;
`;

export default memo(ChatSidebar);
