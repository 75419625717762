import styled from "styled-components";

export const ProvablyFairButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #d2eaff80;
  width: fit-content;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;

  transition: all 0.5s ease, transform 0.1s ease-in-out;
  background: transparent;
  border: 1px solid #d2eaff80;
  padding: 0;

  path {
    fill: currentColor;
    stroke: currentColor;
  }

  .icon {
    padding: 12px;
  }

  .text {
    padding: 8px 16px;
    border-left: 1px solid #d2eaff80;
    text-align: left;
    max-width: 140px;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    .icon {
      padding: 8px;
    }

    .text {
      display: none;
    }
  }
`;
