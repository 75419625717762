import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  // grid-template-areas:
  //   "gamdom gamdom"
  //   "discord kick";

  // @media (max-width: 543px) {
  //   grid-template-areas:
  //     "gamdom"
  //     "discord"
  //     "kick";
  // }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
`;

const Block = styled.div`
  background: #141722;
  box-shadow: 0px 2px 2px 0px #00000026;
  padding: 32px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  min-width: 320px;
`;

export const GamdomBlock = styled(Block)`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const GamdomBlockInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 280px;
`;

export const DiscordBlock = styled(Block)``;

export const KickBlock = styled(Block)``;

export const BlockDescript = styled.p`
  margin: 0;
  font-family: Golos Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #abbadb;
  max-width: 70%;
`;

export const SocialButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-family: Golos Text;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #131635;
  background: #5ebd3d;
  border: none;
  box-shadow: 0px 2px 0px 0px #2a7310;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    // background: #2a3046;
    // box-shadow: 0px 2px 0px 0px #1b2035;
  }

  &:not([disabled]):hover {
    opacity: 0.8;
  }

  &:not([disabled]):active {
    opacity: 0.75;
  }
`;

export const DiscordButton = styled(SocialButton)`
  background: #6a77ff;
  box-shadow: 0px 2px 0px 0px #3642bd;
`;

export const UnlinkButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-family: Golos Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #abbadb;
  background: transparent;
  border: none;
  width: fit-content;
  margin: auto;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:not([disabled]):hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.75;
  }
`;

export const ButtonDescription = styled.div`
  font-family: Golos Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #abbadb;
  text-align: center;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
`;
