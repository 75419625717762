import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  background: #141722;
  border-radius: 12px;
  width: fit-content;
  min-width: 248px;
  padding: 8px;
  height: fit-content;

  @media (max-width: 768px) {
    flex-direction: row;
    overflow: scroll;
    max-width: 100%;
  }
`;

export const NavItem = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 8px;
  color: #abbadb80;

  background: ${({ active }) => (active ? `#6F80AC0D` : "transparent")};

  svg path {
    fill: ${({ active }) => (active ? `#00B2FF` : "")};
  }

  &:hover {
    color: #d5e0f5;
    svg path {
      fill: ${({ active }) => (active ? `#00B2FF` : "#D5E0F5")};
    }
  }

  &:active {
    opacity: 0.75;
  }
`;

export const NavText = styled.span`
  font-family: Golos Text;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
`;
