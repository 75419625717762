import { Root, NavItem, NavText } from "./styles";
import Home from "./icons/home.svg";
import Lock from "./icons/lock.svg";
import History from "./icons/history.svg";
import Research from "./icons/research.svg";
import Ticket from "./icons/ticket.svg";
import W from "./icons/w.svg";
import { ProfileNavEnum, useProfileContext } from "../../ProfileContext";

export const navIconMap = {
  [ProfileNavEnum.Main]: <Home />,
  [ProfileNavEnum.Bets]: <History />,
  // [ProfileNavEnum.Overview]: <Research />,
  // [ProfileNavEnum.Privacy]: <Lock />,
  [ProfileNavEnum.Shop]: <W />,
  [ProfileNavEnum.Raffles]: <Ticket />,
  [ProfileNavEnum.Connections]: <Research />,
};

export type ProfileNavProps = {};

const ProfileNav: React.FC<ProfileNavProps> = () => {
  const { activeTab, changeActiveTab, allowedNav, profile } = useProfileContext();

  return profile ? (
    <Root>
      {Object.entries(allowedNav).map(([key, value]) => (
        <NavItem
          active={activeTab === value}
          key={key}
          onClick={
            () => changeActiveTab(value)
          }
        >
          {navIconMap[value]}
          <NavText>{value}</NavText>
        </NavItem>
      ))}
    </Root>
  ) : null;
};

export default ProfileNav;
