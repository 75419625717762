const gradientMapper = [
  {
    type: "green-violet",
    gameIds: ["spirit-blast"],
  },
  {
    type: "green",
    gameIds: [
      "shaolin-panda-chaos-reels",
      "eternal-clash",
      "royal-ribbit",
      "happy-honey",
      "sweet-smash",
      "walrus-king",
      "crop-of-the-dead",
      "pearly-shores",
      "kongs-coin-chaos",
      "paddy-power-celtic-king-superwild",
      'hot-harvest',
      'blarneys-gold',
      'panda-bills',
      'sheep-king',
      'mutant-potatoes'
    ],
  },
  {
    type: "red",
    gameIds: [
      "eternal-clash-reloaded",
      "cash-strike",
      "gemstone-guardians",
      "boom-the-buffoon",
      "bounty-smash",
      'blazing-touch',
      'cash-inferno-sizzling-scatters',
      'blazing-inferno-chaos-reels'
    ],
  },
  {
    type: "violet",
    gameIds: ["disco-dive", "jackpot-heist-daylight-robbery", "lord-of-the-dunes", 'lollicat'],
  },
  {
    type: "gold",
    gameIds: ["lightning-blitz-chain-collect", "rune-raiders"],
  },
  {
    type: "brown",
    gameIds: ["dystopia-rebel-road", "dystopia-road","barhalla",'teddys-quest','bullet-bonanza', 'flaming-frenzy'],
  },
  {
    type: "dark-blue",
    gameIds: ["midas-moolah-random-reels"],
  },
];

export const getGradientPresets = (gameId: string) => {
  const gradientType = gradientMapper.find((v) => v.gameIds.includes(gameId))?.type;

  switch (gradientType) {
    case "green-violet":
      return ["#48024E59", "#380630B2"];
    case "green":
      return ["#024E3359", "#063838B2"];
    case "red":
      return ["#3C0F0F80", "#330D0D"];
    case "violet":
      return ["#3C0F3680", "#2E0D33"];
    case "gold":
      return ["#F7CC0180", "#FF7917"];
    case "brown":
      return ["#3C1D0F80", "#331F0D"];
    case "dark-blue":
    default:
      return ["#100F3C80", "#100D33"];
  }
};
