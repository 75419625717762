import { useRouter } from "next/router";
import { DropDown, MenuItemLabel, NavItem, Root } from "./styles";
import Dropdown from "../../icons/Dropdown";
import { useEffect, useState } from "react";
import { ITopMenuItem } from "../../../dto";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";
import { useMediaQuery } from "react-responsive";
import React from "react";
import { DateTime } from "luxon";
import useLeaderboardTotalPrize from "hooks/leaderboards/useLeaderboardTotalPrize";

const NavLink: React.FC<{
  item: ITopMenuItem;
  handleCheckboxChange: any;
  itemKey: string;
  setItemKey: React.Dispatch<React.SetStateAction<string>>;
}> = ({ item, handleCheckboxChange, itemKey, setItemKey }) => {
  const isTablet = useMediaQuery({
    query: "(min-width: 480px) and (max-width: 1399px)",
  });

  const { data: leaderboardsTotalPrize } = useLeaderboardTotalPrize();

  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { setShowNavSidebar } = useShowPageLayoutContext();

  useEffect(() => {
    setIsOpen(itemKey === item.name);
  }, [itemKey, item.name]);

  return (
    <Root>
      <NavItem
        href={item.href ?? "/"}
        data-active={item.href === router.pathname}
        data-mobile-only={item.isMobileOnly ?? false}
        data-desktop-only={item.isDesktopOnly ?? false}
        selected={isOpen}
        hasDropdown={!!item.items?.length}
        onClick={(e) => {
          e.preventDefault();

          if (item.items?.length) {
            if (itemKey === item.name) {
              setItemKey("");
            } else {
              setItemKey(item.name);
            }
          } else {
            if (item.href) {
              router.push(item.href);
              handleCheckboxChange();
              if (isTablet) setShowNavSidebar(false);
            }
          }
        }}
      >
        {item.image}
        {item.name}
        {item.items?.length && (
          <Dropdown
            style={{
              marginLeft: "auto",
              transition: "transform 0.3s",
              transform: isOpen ? "rotate(180deg)" : "none",
            }}
          />
        )}
        {item.name === "Leaderboards" && !!leaderboardsTotalPrize && (
          <MenuItemLabel itemName="Leaderboards">{`$${leaderboardsTotalPrize}`}</MenuItemLabel>
        )}

        {["All bonuses", "Calendar"].includes(item.name) && (
          <MenuItemLabel itemName={item.name}>
            {item.name === "All bonuses"
              ? "Free"
              : item.name === "Calendar"
              ? `Day ${DateTime.utc().day}`
              : null}
          </MenuItemLabel>
        )}
      </NavItem>
      <DropDown show={isOpen}>
        {item.items?.map((subItem, index) => (
          <NavLink
            key={`${item.name}_${index}`}
            item={subItem}
            handleCheckboxChange={handleCheckboxChange}
            itemKey={itemKey}
            setItemKey={setItemKey}
          />
        ))}
      </DropDown>
    </Root>
  );
};

export default NavLink;
