import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { IUserDto } from "interfaces";
import MainTab from "./components/TabContent/MainTab";
// TODO sync with design
// import RafflesTab from "./components/TabContent/RafflesTab";
import PointsShopTab from "../ProfileModalTabs/PointsShopTab";
import MyBetsTab from "../ProfileModalTabs/MyBetsTab";
import ConnectionsTab from "./components/TabContent/ConnectionsTab";
import RafflesTab from "../ProfileModalTabs/RafflesTab";
import { useAppContext } from "contexts";
import { triggerNotification } from "components/TheComponents/Notification";
import { ALL_ADMIN_ROLES } from "helpers/constants";
import { useRouter, useSearchParams } from "next/navigation";

export enum ProfileNavEnum {
  Main = "Main",
  Connections = "Connections",
  // TODO sync with design
  // Overview = "Overview",
  Raffles = "Raffles",
  Shop = "Points Shop",
  Bets = "Bets History",
  // TODO sync with design
  // Privacy = "Privacy",

  // OLD
  // TODO FREE CASES
  // TODO TRANSACTION
}

type ProfileContextType = {
  activeTab: ProfileNavEnum;
  changeActiveTab: (value: ProfileNavEnum) => void;
  activeTabContent: () => React.JSX.Element;
  profile: IUserDto | null;
  updateUser: ({ field, value }: { field: string; value: string | number }) => Promise<void>;
  updateAddress: ({ field, value }: { field: string; value: string | number }) => Promise<void>;
  isAdminCanEdit: boolean;
  allowedNav: Partial<Record<keyof typeof ProfileNavEnum, ProfileNavEnum>>;
  isOwnProfile: boolean;
  fetchUser: () => void;
};

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

type ProfileProviderProps = PropsWithChildren & { userId: string };

const ProfileProvider: React.FC<ProfileProviderProps> = ({ children, userId }) => {
  const { refetchProfile, accessFetch, profile, apiService } = useAppContext();
  const params = useSearchParams();

  const isOwnProfile = userId === profile?.id;
  const [contextUser, setContextUser] = useState<IUserDto | null>(null);

  const [activeTab, setActiveTab] = useState<ProfileNavEnum>(ProfileNavEnum.Main);
  const changeActiveTab = (value: ProfileNavEnum) => {
    setActiveTab(value);
  };

  useEffect(() => {
    const initTab = params.get("selectedTab");
    switch (initTab) {
      case "connections":
        setActiveTab(ProfileNavEnum.Connections);
        break;
    }
  }, [params]);

  const activeTabContent = useMemo(() => {
    // eslint-disable-next-line react/display-name
    return () => {
      if (contextUser) {
        switch (activeTab) {
          case ProfileNavEnum.Bets:
            return <MyBetsTab userId={contextUser.id} />;
          case ProfileNavEnum.Connections:
            return <ConnectionsTab isCanEdit={isOwnProfile} />;
          case ProfileNavEnum.Raffles:
            return <RafflesTab user={contextUser} />;
          case ProfileNavEnum.Shop:
            return <PointsShopTab user={contextUser} />;
          case ProfileNavEnum.Main:
          default:
            return <MainTab />;
        }
      } else {
        return <></>;
      }
    };
  }, [activeTab, contextUser, isOwnProfile]);

  const fetchUser = useCallback(async () => {
    if (isOwnProfile) {
      const { data } = await refetchProfile();
      if (data) {
        setContextUser(data);
      }
    } else {
      if (userId) {
        try {
          const userDataResponse = await apiService.request(
            `/user/${userId}?withStats=true&withCrypto=true`
          );
          const userData = await userDataResponse?.json();
          setContextUser(userData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  }, [isOwnProfile, refetchProfile, userId, apiService]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const updateAddress = useCallback(
    async ({ field, value }: { field: string; value: string | number }) => {
      try {
        const response = await accessFetch(`/user/${userId}/crypto-addresses`, {
          method: "PATCH",
          body: JSON.stringify({
            [field]: value,
          }),
        });
        if (response && (response.status === 200 || response.status === 201)) {
          triggerNotification({ type: "success", text: "User updated" });
          fetchUser();
        } else {
          triggerNotification({ type: "error", text: "Something went wrong" });
        }
      } catch (error) {
        triggerNotification({ type: "error", text: (error as any)?.message ?? "Error" });
        console.error("Error fetching data:", error);
      }
    },
    [accessFetch, userId, fetchUser]
  );

  const updateUser = useCallback(
    async ({ field, value }: { field: string; value: string | number }) => {
      try {
        const response = await accessFetch(`/user/${userId}/`, {
          method: "PATCH",
          body: JSON.stringify({
            [field]: value,
          }),
        });
        if (response && (response.status === 200 || response.status === 201)) {
          triggerNotification({ type: "success", text: "User updated" });
          fetchUser();
        } else {
          triggerNotification({ type: "error", text: "Something went wrong" });
        }
      } catch (error) {
        triggerNotification({ type: "error", text: (error as any)?.message ?? "Error" });
        console.error("Error fetching data:", error);
      }
    },
    [accessFetch, userId, fetchUser]
  );

  const canSeeRafflesTab = isOwnProfile
    ? true
    : !!profile?.roles.some(
        (role) =>
          role.name === ALL_ADMIN_ROLES.SUPER_ADMIN ||
          role.name === ALL_ADMIN_ROLES.RAFFLE_ADMIN ||
          role.name === ALL_ADMIN_ROLES.USERS_ADMIN
      );

  const canSeePointsShopTab = isOwnProfile
    ? true
    : !!profile?.roles.some(
        (role) =>
          role.name === ALL_ADMIN_ROLES.SUPER_ADMIN ||
          role.name === ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN ||
          role.name === ALL_ADMIN_ROLES.USERS_ADMIN
      );

  const canSeeConnectionsTab = isOwnProfile
    ? true
    : !!profile?.roles.some(
        (role) =>
          role.name === ALL_ADMIN_ROLES.SUPER_ADMIN || role.name === ALL_ADMIN_ROLES.USERS_ADMIN
      );

  const isAdminCanEdit = !!profile?.roles.some(
    (role) => role.name === ALL_ADMIN_ROLES.SUPER_ADMIN || role.name === ALL_ADMIN_ROLES.USERS_ADMIN
  );

  const allowedNav = Object.entries(ProfileNavEnum)
    .filter(([_, value]) => {
      if (value === ProfileNavEnum.Shop && !canSeePointsShopTab) return false;
      if (value === ProfileNavEnum.Connections && !canSeeConnectionsTab) return false;
      if (value === ProfileNavEnum.Raffles && !canSeeRafflesTab) return false;
      return true;
    })
    .reduce((acc, [key, value]) => {
      acc[key as keyof typeof ProfileNavEnum] = value;
      return acc;
    }, {} as Partial<Record<keyof typeof ProfileNavEnum, ProfileNavEnum>>);

  return (
    <ProfileContext.Provider
      value={{
        activeTab,
        changeActiveTab,
        activeTabContent,
        profile: contextUser,
        updateUser,
        updateAddress,
        isAdminCanEdit,
        allowedNav,
        isOwnProfile,
        fetchUser,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

const useProfileContext = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfileContext must be used within a ProfileProvider");
  }

  return context;
};

export { ProfileProvider, useProfileContext };
