import Link from "next/link";
import styled from "styled-components";

export const Root = styled.div`
  position: relative;

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
`;

export const NavItem = styled(Link)<{ selected: boolean; hasDropdown: boolean }>`
  display: flex;
  position: relative;
  height: 48px;
  width: 100%;
  align-items: center;
  gap: 8px;
  padding: 12px 12px 12px 16px;
  background-color: var(--top-menu-bg);
  border-radius: 8px;
  font-family: var(--font-family-golos);
  color: var(--clouds-100);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;

  &[data-mobile-only="true"] {
    display: none;
  }

  &[data-active="true"] {
    background-color: rgba(29, 34, 52, 0.5);
    color: var(--daylight-color);

    & path {
      fill: var(--yellow);
      color: var(--daylight-color);
      background-color: rgba(29, 34, 52, 0.5);
    }
  }

  & path {
    margin-left: auto;
    ${({ selected }) => (selected ? `fill: var(--pure-sky)` : `fill: var(--clouds-100);`)}
  }

  &:hover {
    background-color: rgba(29, 34, 52, 0.5);
    color: (var--daylight-color);
  }

  ${({ hasDropdown }) =>
    hasDropdown &&
    `
        background-color: rgba(29, 34, 52, 0.50);
        color: var(--clouds-100);
        margin-bottom: 8px;

        &:hover {
            background-color: rgba(29, 34, 52, 0.5);
        }
    `}

  ${({ selected }) =>
    selected &&
    `
        background-color: rgba(29, 34, 52, 0.50);
        color: var(--daylight-color);
        &: hover {
            background-color: rgba(29, 34, 52, 0.50);
            color: var(--daylight-color);
        }
    `}

  @media (max-width: 1280px) {
    &[data-mobile-only="true"] {
      display: flex;
    }
    &[data-desktop-only="true"] {
      display: none;
    }
  }
`;

export const DropDown = styled.div<{ show?: boolean }>`
  position: static;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  flex-basis: 0;
  transition: all 0.5s ease;
  gap: 8px;

  & ${NavItem} {
    padding: 12px 12px 12px 24px;
  }

  ${({ show }) =>
    show
      ? `
      max-height: 100vh;
      transition: max-height 0.5s ease-in;
      flex: 1;
      display: flex;
      width: 100%;

      & > div {
        width: 100%;
      }`
      : `
          max-height: 0;
          transition: max-height 0.15s ease-out;
          overflow: hidden;  
    `}
`;

export const MenuItemLabel = styled.div<{ itemName?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  min-width: 48px;
  padding: 8px 5px;
  text-transform: uppercase;
  color: ${({ itemName }) => itemName === "Calendar" ? "var(--yellow)" : "#101622"};
  font-family: var(--font-family-golos);
  font-size: 12px;
  font-weight: 600;
  margin-left: auto;
  border-radius: 4px;

  background-color: ${({ itemName }) => {
    switch (itemName) {
      case "Leaderboards":
        return "var(--yellow)";
      case "All bonuses":
        return "var(--green)";
      case "Calendar":
        return "rgba(242, 201, 76, 0.10)";
    }
  }};
`;
