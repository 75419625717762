import styled from "styled-components";

export const Input = styled.input`
  padding: 12px;
  flex: auto;
  background: transparent;
  color: #d5e0f5;
  font-family: Golos Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  outline: none;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
`;
