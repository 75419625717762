import { DetailedHTMLProps, InputHTMLAttributes, useRef, useState } from "react";
import { Root } from "./styles";
import { Input } from "../styles";
import SaveButton from "../SaveButton";

export type TextInputProps = {
  text: string;
  placeholder?: string;
  saveNewValue?: (value: string) => void;
  disabled?: boolean;
  inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
};

const TextInput: React.FC<TextInputProps> = ({ text, placeholder, saveNewValue, disabled, inputProps }) => {
  const [value, setValue] = useState<string>(text ?? "");
  const [isActive, setIsActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Root active={isActive}>
      <Input
        {...inputProps}
        placeholder={placeholder}
        disabled={!isActive}
        ref={inputRef}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      {!disabled && <SaveButton
        isActive={isActive}
        onClick={() => {
          setIsActive((prev) => {
            if (prev) {
              saveNewValue?.(value);
            }
            return !prev;
          });
          setTimeout(() => {
            inputRef.current?.focus();
          }, 100);
        }}
      />}
    </Root>
  );
};

export default TextInput;
