import Image from "next/image";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles, TheIcon } from "components";
import { SlidingVideos } from "./SlidingVideos";
import { ArrowTriangle } from "icons";
import React from "react";

interface FeedInfo {
  url: string;
  title: string;
  link: string;
  author: string;
  description: string;
  image: string;
}

interface Enclosure {
  link: string;
  type: string;
  thumbnail: string;
}

export interface Item {
  title: string;
  pubDate: string;
  link: string;
  guid: string;
  author: string;
  thumbnail: string;
  description: string;
  content: string;
  enclosure: Enclosure;
  categories: string[];
}

interface FeedResponse {
  status: string;
  feed: FeedInfo;
  items: Item[];
}

export interface IYoutubeFeedComponent {
  id?: string;
  highlights?: Item[];
  latest?: Item[];
  range?: number;
}

export const YoutubeFeedComponent: React.FC<IYoutubeFeedComponent & IStyles> = ({
  id,
  highlights = [],
  latest = [],
  range: rangeInit = 4,
}): JSX.Element => {
  const boxRef = React.useRef<HTMLDivElement | null>(null);
  const latestSectionRef = React.useRef<HTMLElement>(null);
  const highlightsSectionRef = React.useRef<HTMLElement>(null);

  const [indexHighlights, setIndexHighlights] = React.useState<number>(0);
  const [indexLatest, setIndexLatest] = React.useState<number>(0);
  const [range, setRange] = React.useState<number>(rangeInit);
  // Latest videos state
  const [canScrollLeftLatest, setCanScrollLeftLatest] = React.useState(false);
  const [canScrollRightLatest, setCanScrollRightLatest] = React.useState(true);

  // Highlights videos state
  const [canScrollLeftHighlights, setCanScrollLeftHighlights] = React.useState(false);
  const [canScrollRightHighlights, setCanScrollRightHighlights] = React.useState(true);

  // Check scroll position for a given ref
  const checkScrollPosition = React.useCallback(
    (
      sectionRef: React.RefObject<HTMLElement>,
      setCanScrollLeft: (value: boolean) => void,
      setCanScrollRight: (value: boolean) => void
    ) => {
      if (sectionRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = sectionRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
      }
    },
    []
  );

  // Scroll left or right for a given section
  const scrollLeft = React.useCallback((sectionRef: React.RefObject<HTMLElement>) => {
    if (sectionRef.current) {
      sectionRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  }, []);

  const scrollRight = React.useCallback((sectionRef: React.RefObject<HTMLElement>) => {
    if (sectionRef.current) {
      sectionRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  }, []);

  React.useEffect(() => {
    const latestSection = latestSectionRef.current;
    const highlightsSection = highlightsSectionRef.current;

    // Check initial scroll position
    checkScrollPosition(latestSectionRef, setCanScrollLeftLatest, setCanScrollRightLatest);
    checkScrollPosition(
      highlightsSectionRef,
      setCanScrollLeftHighlights,
      setCanScrollRightHighlights
    );

    // Add scroll listeners for latest and highlights sections
    if (latestSection) {
      latestSection.addEventListener("scroll", () =>
        checkScrollPosition(latestSectionRef, setCanScrollLeftLatest, setCanScrollRightLatest)
      );
    }
    if (highlightsSection) {
      highlightsSection.addEventListener("scroll", () =>
        checkScrollPosition(
          highlightsSectionRef,
          setCanScrollLeftHighlights,
          setCanScrollRightHighlights
        )
      );
    }

    return () => {
      if (latestSection) {
        latestSection.removeEventListener("scroll", () =>
          checkScrollPosition(latestSectionRef, setCanScrollLeftLatest, setCanScrollRightLatest)
        );
      }
      if (highlightsSection) {
        highlightsSection.removeEventListener("scroll", () =>
          checkScrollPosition(
            highlightsSectionRef,
            setCanScrollLeftHighlights,
            setCanScrollRightHighlights
          )
        );
      }
    };
  }, [checkScrollPosition]);

  React.useEffect(() => {
    const onResize = () => {
      if (boxRef.current) {
        const node = boxRef.current;
        const { offsetWidth } = node;
        let range = 1;
        if (offsetWidth >= (344 + 24) * 3 + 344 * 0.5) {
          range = 4;
        } else if (offsetWidth >= (344 + 24) * 2 + 344 * 0.5) {
          range = 3;
        } else if (offsetWidth >= (344 + 24) * 1 + 344 * 0.5) {
          range = 2;
        } else if (offsetWidth >= (344 + 24) * 0 + 344 * 0.5) {
          range = 1;
        }
        setRange(range);
      }
    };

    onResize();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  React.useEffect(() => {
    setIndexHighlights(0);
  }, [highlights, setIndexHighlights]);

  React.useEffect(() => {
    setIndexLatest(0);
  }, [latest, setIndexLatest]);

  return (
    <Box id={id} className="container" ref={boxRef}>
      <Content range={range}>
        <TextTitle>
          Check out WGTV
          <br />
          <span data-blue>latest content</span>
        </TextTitle>
        <BoxH3>
          <TheIcon icon="youtube:small" size={16} />
          <span data-grow>Latest videos</span>
          <ScrollToolbar>
            <SlideButton
              left
              onClick={() => scrollLeft(latestSectionRef)}
              disabled={!canScrollLeftLatest}
            >
              <ArrowTriangle />
            </SlideButton>
            <SlideButton
              onClick={() => scrollRight(latestSectionRef)}
              disabled={!canScrollRightLatest}
            >
              <ArrowTriangle />
            </SlideButton>
          </ScrollToolbar>
        </BoxH3>
        <CardsFrame
          styles={css`
            --Cards-offset-index: ${indexLatest};
          `}
          range={range}
        >
          <SlidingVideos videos={latest} youTubeSectionRef={latestSectionRef} />
        </CardsFrame>
        <BoxH3>
          <TheIcon icon="star:small" size={16} />
          <span data-grow>Highlights videos</span>
          <ScrollToolbar>
            <SlideButton
              left
              onClick={() => scrollLeft(highlightsSectionRef)}
              disabled={!canScrollLeftHighlights}
            >
              <ArrowTriangle />
            </SlideButton>
            <SlideButton
              onClick={() => scrollRight(highlightsSectionRef)}
              disabled={!canScrollRightHighlights}
            >
              <ArrowTriangle />
            </SlideButton>
          </ScrollToolbar>
        </BoxH3>
        <CardsFrame
          styles={css`
            --Cards-offset-index: ${indexHighlights};
          `}
          range={range}
        >
          <SlidingVideos videos={highlights} youTubeSectionRef={highlightsSectionRef} />
        </CardsFrame>
      </Content>
    </Box>
  );
};

export const fetcherYoutubeFeedComponent = async (): Promise<IYoutubeFeedComponent> => {
  let highlights: Item[] = [];
  let latest: Item[] = [];

  try {
    [highlights, latest] = await Promise.all(
      [
        `https://api.rss2json.com/v1/api.json?rss_url=https://www.youtube.com/feeds/videos.xml?playlist_id=PLKiKtzS61PLZ7DFdJiNU4i9YA6dSu7iGL&api_key=${process.env.NEXT_PUBLIC_YOUTUBE_VIDEOS_API_KEY}`,
        `https://api.rss2json.com/v1/api.json?rss_url=https://www.youtube.com/feeds/videos.xml?channel_id=UCMpAWk-kF478wyR44Gobz8g&api_key=${process.env.NEXT_PUBLIC_YOUTUBE_VIDEOS_API_KEY}`,
      ].map(async (url: string) => {
        const response = await fetch(url);
        const json: FeedResponse = await response.json();
        const { items } = json;
        return items;
      })
    );
  } catch (error) {
    console.error(error);
  }

  return { highlights, latest };
};

const Box = styled.div`
  margin: 24px auto;
`;

const Screen = styled.div`
  ${$stylesReset}
  position: relative;
  max-width: 1920px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  overflow: hidden;
`;

const Content = styled.div<IStyles & { range?: number }>`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  margin: 24px;
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const TextTitle = styled(Text)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--daylight-color);
  text-align: center;
  font-family: var(--font-family-exo);
  font-size: 24px;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 35px;

  & [data-blue] {
    color: #3b8be9;
    font-size: 48px;
    @media (max-width: 800px) {
      font-size: 30px;
    }
  }
  @media (max-width: 800px) {
    color: #d2eaff;
    font-size: 20px;
  }
  min-width: 300px;
`;

const Img = styled(Image)<IStyles>`
  ${({ styles }) => styles}
`;

// const cssLinkArrow = css`
//   width: 28px;
//   height: 28px;
//   padding: 8px;
//   border: 1px solid rgba(210, 234, 255, 0.05);
//   border-radius: 8px;
// `;

// const cssArrow = css``;

const BoxH3 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  min-width: 300px;
  margin: 8px 0 24px;
  color: rgba(210, 234, 255, 0.75);
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 150%;
  font-weight: 500;

  & [data-grow] {
    flex-grow: 1;
    text-align: left;
  }
`;

const CardsFrame = styled.div<IStyles & { range?: number }>`
  --Card-zoom: calc(1 / 1.4);
  --Card-width: calc(480px * var(--Card-zoom));
  --Card-height: calc(360px * var(--Card-zoom));
  --Card-gap: 24px;
  --Cards-offset-index: 0;

  box-sizing: border-box;
  position: relative;
  ${({ range = 4 }) =>
    range > 1
      ? css`
          width: calc(var(--Card-width) * ${range} + var(--Card-gap) * ${range - 1});
        `
      : css`
          width: calc(var(--Card-width));
        `}
  height: 330px;
  overflow: hidden;
  max-width: 100%;

  ${({ styles }) => styles}
`;

const ScrollToolbar = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: 1px;
`;

const SlideButton = styled.div<{ left?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border-radius: 8px;
  transition: all 0.3s;
  ${({ left }) => (left ? "transform: rotate(180deg);" : "")};
  /* opacity: ${({ disabled }) => (disabled ? "0.5" : "1")}; */
  background-color: ${({ disabled }) => (disabled ? "transparent" : "rgba(171, 186, 219, 0.05)")};
  border: 1px solid ${({ disabled }) => (disabled ? "rgba(200, 215, 245, 0.05)" : "transparent")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  & path {
    fill: ${({ disabled }) => (disabled ? "rgba(171, 186, 219, 0.05)" : "#C8D7F5")};
  }
`;
