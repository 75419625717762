import React, { useEffect, useState } from "react";
import NavLink from "./NavLink";
import { DropNav, MobileHeaderBlock, Nav } from "../../styles";
import { ITopMenuItem, menuItems } from "../../dto";
import { filterNavItems } from "../../utils/filterNavItems";
import { useAppContext } from "contexts";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";
import {
  CoinButton,
  CoinItem,
  CoinItemDescription,
  CoinItemHeader,
  CoinItemInfo,
  DescriptionValue,
} from "components/games/GamesComponent/styles";
import Image from "next/image";
import { triggerNotification } from "components/TheComponents/Notification";

const Navigation = () => {
  const { rates, profile, isAdmin, isFeatureEnabled, featureFlags } = useAppContext();
  const [items, setItems] = React.useState<ITopMenuItem[]>([]);
  const [itemKey, setItemKey] = React.useState<string>("");
  const [isOpenDropdown, setIsOpenDropdown] = React.useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const { showNavSidebar } = useShowPageLayoutContext();

  useEffect(() => {
    const newItems = filterNavItems(menuItems, isFeatureEnabled, profile, isAdmin);
    setItems(newItems);
  }, [profile, isFeatureEnabled, featureFlags, isAdmin]);

  const exchange = () => {
    if (profile) {
      setIsShowModal(true);
    } else {
      triggerNotification({ text: "Please, login for exchage points", type: "error" });
    }
  };

  const onToggle = () => {
    setIsOpenDropdown((prev) => !prev);
  };

  const itemsMenu = items.reduce<{
    interactive: ITopMenuItem[];
    nonInteractive: ITopMenuItem[];
  }>(
    (acc, item) => {
      item.isInteractive ? acc.interactive.push(item) : acc.nonInteractive.push(item);
      return acc;
    },
    { interactive: [], nonInteractive: [] }
  );

  return (
    <Nav show={showNavSidebar}>
      <MobileHeaderBlock>
        <DropNav>
          {itemsMenu.interactive.map((item, index) => (
            <NavLink
              key={index}
              item={item}
              handleCheckboxChange={onToggle}
              itemKey={itemKey}
              setItemKey={setItemKey}
            />
          ))}
        </DropNav>
      </MobileHeaderBlock>
      <DropNav>
        {itemsMenu.nonInteractive.map((item, index) => (
          <NavLink
            key={index}
            item={item}
            handleCheckboxChange={onToggle}
            itemKey={itemKey}
            setItemKey={setItemKey}
          />
        ))}
      </DropNav>

      {/* <CoinItem>
        <Image alt="" src="/images/games/w-g-coins.svg" width={226} height={226} /> 
        <CoinItemInfo>
          <CoinItemHeader>GPoints Exchange</CoinItemHeader>
          <CoinItemDescription>
            Exchange your WPoints into GPoints
            <br />
            With rate{" "}
            <DescriptionValue>
              <Image alt="" src="/images/coins/w-coin.svg" width={20} height={20} /> 1
            </DescriptionValue>
            ={" "}
            <DescriptionValue>
              <Image alt="" src="/images/coins/g-coin.svg" width={20} height={20} />{" "}
              {1 * (rates?.point_gpoint_rate ?? 1)}
            </DescriptionValue>
          </CoinItemDescription>
          <CoinButton color="gold" onClick={exchange}>
            Exchange
          </CoinButton>
        </CoinItemInfo>
      </CoinItem> */}
    </Nav>
  );
};

export default Navigation;
