import {
  Crown,
  Gift,
  Others,
  Support,
  Contact,
  Profile,
  Seven,
  DropLines,
  W,
  Chat,
  Calendar,
  Ticket,
  Pinball,
  HuntTracker,
} from './components/icons';

import { ReactNode } from "react";


export const menuItems: ITopMenuItem[] = [
  {
    name: "Admin",
    href: "/admin",
    type: "admin",
    image: <Profile />,
    isInteractive: true,
    isDesktopOnly: false,
  },
  {
    name: "Leaderboards",
    href: "/leaderboards",
    isInteractive: true,
    image: <Crown />,
  },
  {
    name: "All bonuses",
    href: "/all-bonuses",
    isInteractive: true,
    image: <Gift />,
  },
  {
    name: "Rewards",
    image: <W />,
    items: [
      {
        name: "Calendar",
        href: "/advent-calendar",
        image: <Calendar />,
      },
      {
        name: "Raffles",
        href: "/raffles",
        image: <Ticket />,
      },
      {
        name: "Points Shop",
        href: "/points-shop",
        image: <W />,
      },
      {
        name: "Pachinko",
        href: "/pachinko",
        image: <Pinball />,
      },
    ],
  },
  {
    name: "Others",
    image: <Others />,
    items: [
      {
        name: "Hunt Tracker",
        href: "/hunt-tracker",
        image: <HuntTracker />,
      },
    ],
  },
];

export const addMenuItems: ITopMenuItem[] = [
  {
    name: "Support",
    href: "/faq",
    image: <Support />,
  },
  {
    name: "Contact",
    href: "/contact-us",
    image: <Contact />,
  },
];

export const metaMenuItems: ITopMenuItem[] = [
  {
    name: "Privacy Policy",
    href: "/privacy-policy",
  },
  {
    name: "Terms of Service",
    href: "/terms-of-service",
  },
];
export interface ITopMenuItem {
  name: string;
  href?: string;
  type?: string;
  image?: ReactNode;
  isMobileOnly?: boolean;
  isDesktopOnly?: boolean;
  isInteractive?: boolean;
  items?: ITopMenuItem[];
}
export interface IMobileMenuItem {
  name: string;
  icon?: (isActive: boolean) => JSX.Element;
}

export const metaMobileMenuItems: IMobileMenuItem[] = [
  {
    name: "browse",
    icon: (isActive: boolean) => (
      <DropLines fill={isActive ? "var(--pure-sky)" : "var(--clouds-100)"} />
    ),
  },
  {
    name: "games",
    icon: (isActive: boolean) => (
      <Seven fill={isActive ? "var(--pure-sky)" : "var(--clouds-100)"} />
    ),
  },
  {
    name: "bonuses",
    icon: (isActive: boolean) => <Gift fill={isActive ? "var(--pure-sky)" : "var(--clouds-100)"} />,
  },
  {
    name: "chat",
    icon: (isActive: boolean) => <Chat fill={isActive ? "var(--pure-sky)" : "var(--clouds-100)"} />,
  },
  {
    name: "profile",
    icon: (isActive: boolean) => (
      <Profile fill={isActive ? "var(--pure-sky)" : "var(--clouds-100)"} />
    ),
  },
];
