import { FooterComponent } from "./FooterComponent";
import styled from "styled-components";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";
import Backdrop from "./Backdrop";
import { useMediaQuery } from "react-responsive";
import ChatSidebar from "./ChatSidebar";
import dynamic from "next/dynamic";
import Navigation from "./HeaderComponent/components/Navigation";
import { useEffect, useState } from "react";
import { ProfileProvider } from "components/public/ProfileLayout/ProfileContext";
import { useAppContext } from "contexts";

const HeaderComponent = dynamic(() => import("layout/HeaderComponent"), {
  ssr: false,
});

const PageLayout = ({ children }: any) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 1399px)",
  });

  const isPhone = useMediaQuery({
    query: "(max-width: 500px)",
  });

  const { showNavSidebar } = useShowPageLayoutContext();
  const { showChatSidebar } = useShowPageLayoutContext();
  const { profile } = useAppContext();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 600);
  }, []);

  return (
    <Page style={isLoaded ? { opacity: 1 } : { opacity: 0 }}>
      <ProfileProvider userId={profile?.id || ""}>
        <HeaderComponent id="Header" />

        <PageContent>
          <Navigation />
          <ContentWrapper>
            <CurrentContentWrapper>{children}</CurrentContentWrapper>
            <FooterComponent id="Footer" />
          </ContentWrapper>
        </PageContent>

        <Backdrop show={(showNavSidebar || showChatSidebar) && isMobile} />
        
        {!isPhone && <ChatSidebar show={showChatSidebar} />}
      </ProfileProvider>
    </Page>
  );
};

const Page = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  transition: opacity 0.4s;
  opacity: 0;
`;

const PageContent = styled.div`
  display: flex;
  width: 100%;
`;

const CurrentContentWrapper = styled.div`
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  width: calc(100% - var(--sidebar-width));
  position: relative;
  left: var(--sidebar-width);
  padding-top: var(--header-height);

  @media (max-width: 500px) {
    padding-top: var(--mobile-header-height);
  }

  @media (max-width: 1400px) {
    width: 100%;
    left: 0px;
    position: relative;
    height: auto;
    overflow: hidden;
  }
`;

export default PageLayout;
