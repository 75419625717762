import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styled from "styled-components";

type Item = {
  value: string | number;
  label: string;
  disabled?: boolean;
};

type Props<T extends Item> = {
  items: T[];
  prefix?: React.ReactNode;
  icon?: React.ReactNode;
  value?: T["value"];
  onChange?: (selected: T) => void;
};

function WrewardsDropdown<T extends Item>({ items = [], prefix, icon, value, onChange }: Props<T>) {
  return (
    <Menu>
      {({ isOpen }) => (
        <MenuWrapper>
          <MenuButton isActive={isOpen} as={StyledMenuButton}>
            {prefix && <span className="prefix">{prefix}</span>}
            <span className="value">{items.find((i) => i.value === value)?.label ?? ""}</span>
            {icon && <span className="icon">{icon}</span>}
          </MenuButton>
          <MenuList rootProps={{ width: "100%" }} as={StyledList}>
            {items.map((item) => (
              <MenuItem
                key={item.value}
                disabled={item.disabled}
                value={item.value}
                as={MenuItemStyled}
                onClick={() => onChange?.(item)}
              >
                {item.label}
              </MenuItem>
            ))}
          </MenuList>
        </MenuWrapper>
      )}
    </Menu>
  );
}

const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  justify-items: stretch;

  button {
    width: 100%;
  }
`;
const MenuItemStyled = styled.button`
  &.chakra-menu__menuitem {
    font-family: var(--font-family-golos);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #00b2ff;
    padding: 8px 6px;

    &:hover,
    &:focus {
      background-color: rgba(0, 178, 255, 0.05);
      border-radius: 8px;
    }
  }
`;

const StyledList = styled(MenuList)`
  &.chakra-menu__menu-list {
    overflow: hidden;
    padding: 6px;

    --menu-bg: #181e30;
    border-radius: 8px;
    border: 1px solid var(--pure-sky-100, #00b2ff);
    /* background: var(--pure-sky-5, rgba(0, 178, 255, 0.05)); */
  }
`;

const StyledMenuButton = styled.button`
  border-radius: 8px;
  background: var(--Early-Morning, #1e263e);
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 500;
  color: rgba(171, 186, 219, 0.5);
  padding: 12px 16px;
  border: 1px solid var(--dark-foggy-night-50, rgba(29, 34, 52, 0.5));

  .icon {
    width: 24px;
    height: 24px;
    border-radius: 40px;
    border: 2px solid var(--clouds-25, rgba(171, 186, 219, 0.03));
    background: var(--clouds-10, rgba(171, 186, 219, 0.1));
    transition: 0.2s all;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-180deg);
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &[aria-expanded="true"] {
    border-color: #00b2ff;
    background: var(--pure-sky-5, rgba(0, 178, 255, 0.05));

    .icon {
      border: 2px solid var(--pure-sky-5, rgba(0, 178, 255, 0.05));
      background: var(--pure-sky-15, rgba(0, 178, 255, 0.15));
      transform: rotate(0deg);
    }
  }

  & > span {
    display: flex;
    gap: 8px;
    align-items: center;

    .icon {
      margin-left: auto;
    }
  }

  .value {
    min-width: 80px;
    color: #00b2ff;
  }
`;

export default WrewardsDropdown;
