import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  & > div {
    margin: 0;
  }
  & table {
    border-spacing: 0 8px;
    tbody {
      tr {
        background: #24274680;
      }
    }
  }

  @media (max-width: 600px) {
    overflow: scroll;
    td {
      padding: 4px 8px;
    }
  }
`;

export const TableHeader = styled.h6`
  margin: 0;
  width: 100%;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #e9f4ff80;
  text-transform: uppercase;
`;

export const Cell = styled.div<{ withPoints?: boolean }>`
  display: flex;
  ${({ withPoints }) =>
    withPoints
      ? `
        justify-content: flex-start;
        padding-left: 24px;
        @media (max-width: 600px) {
          padding-left: 0;
        }
        `
      : `justify-content: center;`}
  align-items: center;
  gap: 4px;

  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  color: #e9f4ffbf;
`;
