
import {
  ProfileTab,
  ProfileTabHeader,
  ProfileTabTitle,
  ProfileTabDescription,
  ProfileTabBlock,
  DescriptionLink,
} from "../styles";
import AddressInput, { WalletAssetEnum } from "../components/AddressInput";
import TextInput from "../components/TextInput";
import { useProfileContext } from "components/public/ProfileLayout/ProfileContext";


export type MainTabProps = {
};

const MainTab: React.FC<MainTabProps> = () => {
  const { profile, updateAddress, updateUser, isAdminCanEdit } = useProfileContext()
  return profile ? (
    <ProfileTab>
      <ProfileTabBlock>
        <ProfileTabHeader>
          <ProfileTabTitle>Main</ProfileTabTitle>
          <ProfileTabDescription>
            Set up your crypto wallets to instantly receive your winnings
          </ProfileTabDescription>
        </ProfileTabHeader>
        <AddressInput
          disabled={!isAdminCanEdit}
          asset={WalletAssetEnum.BTC}
          address={profile.btcAddress ?? ""}
          saveNewAddress={async (value) => {
            await updateAddress({
              field: 'btcAddress',
              value
            })
          }} />
        <AddressInput

          disabled={!isAdminCanEdit}
          asset={WalletAssetEnum.ETH}
          address={profile.ethAddress ?? ""}
          saveNewAddress={async (value) => {
            await updateAddress({
              field: 'ethAddress',
              value
            })
          }}
        />
        <AddressInput
          disabled={!isAdminCanEdit}
          asset={WalletAssetEnum.LTC}
          address={profile.ltcAddress ?? ""}
          saveNewAddress={async (value) => {
            await updateAddress({
              field: 'ltcAddress',
              value
            })
          }}
        />
        <AddressInput
          disabled={!isAdminCanEdit}
          asset={WalletAssetEnum.USDT_ERC}
          address={profile.usdtErc20Address ?? ""}
          saveNewAddress={async (value) => {
            await updateAddress({
              field: 'usdtErc20Address',
              value
            })
          }}
        />
      </ProfileTabBlock>
      <ProfileTabBlock>
        <ProfileTabHeader>
          <ProfileTabTitle>Steam Trade URL</ProfileTabTitle>
          <ProfileTabDescription>
            Input{" "}
            <DescriptionLink
              target="_blank"
              href="https://steamcommunity.com/id/me/tradeoffers/privacy"
              rel="noopener noreferrer"
            >
              Steam Tradelink
            </DescriptionLink>{" "}
            below
          </ProfileTabDescription>
        </ProfileTabHeader>
        <TextInput
          disabled={!isAdminCanEdit} text={profile.steamTradeLink ?? ""} placeholder="Your Trade link" saveNewValue={async (value) => {
            await updateUser({
              field: 'steamTradeLink',
              value,
            })
          }} />
      </ProfileTabBlock>
    </ProfileTab>
  ) : <></>;
};

export default MainTab;
