import { useRef, useState } from "react";
import { Root, Label, ImageWrapper } from "./styles";
import Image from "next/image";
import { Input } from "../styles";
import SaveButton from "../SaveButton";
import { validate } from "multicoin-address-validator";
import { triggerNotification } from "components/TheComponents/Notification";

export enum WalletAssetEnum {
  BTC = "BTC",
  ETH = "ETH",
  LTC = "LTC",
  USDT = "USDT",
  USDT_ERC = "USDT ERC",
  // TODO
  // USDT_TRC = "USDT TRC",
}

export type AddressInputProps = {
  asset: WalletAssetEnum;
  address: string;
  saveNewAddress: (value: string) => void
  disabled?: boolean;
};

const assetIconMap = {
  [WalletAssetEnum.BTC]: "/images/icons/btc.svg",
  [WalletAssetEnum.ETH]: "/images/icons/eth.svg",
  [WalletAssetEnum.LTC]: "/images/icons/eth.svg",
  [WalletAssetEnum.USDT]: "/images/icons/usdt.svg",
  [WalletAssetEnum.USDT_ERC]: "/images/icons/usdt.svg",
  // [WalletAssetEnum.USDT_TRC]: "/images/icons/usdt.svg",
};

const AddressInput: React.FC<AddressInputProps> = ({ asset, address, saveNewAddress, disabled }) => {
  const [value, setValue] = useState<string>(address ?? "");
  const [isActive, setIsActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Root active={isActive}>
      <Label>
        <ImageWrapper>
          <Image src={assetIconMap[asset]} alt={asset} height={10} width={10} />
        </ImageWrapper>
        {asset}
      </Label>
      <Input
        disabled={!isActive}
        ref={inputRef}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      {!disabled && <SaveButton
        isActive={isActive}
        onClick={() => {
          setIsActive((prev) => {
            if (prev) {
              if (
                validate(
                  value,
                  asset === WalletAssetEnum.USDT_ERC
                    ? 'eth'
                    : asset
                )) {
                saveNewAddress(value);
              } else {
                triggerNotification({
                  type: "error",
                  text: "Address validation error",
                })
              }

            }
            return !prev;
          });
          setTimeout(() => {
            inputRef.current?.focus();
          }, 100);
        }}
      />}
    </Root>
  );
};

export default AddressInput;
