import React, { useCallback } from "react";
import { ITheHeaderProfile } from "interfaces";
import Image from "next/image";
import { ThePointsBalance } from "../../../../../components/TheComponents/ThePointsBalance";
import {
  DesktopView,
  Text,
  CalendarLink,
  ExchangeBtn,
  MenuBtn,
  MenuListStyled,
  BalanceTile,
  ProfileDisplayName,
  ProfileListStyled,
  ProfileListItem,
  BalanceBlock,
  BalanceBtn,
  ProfileBtn,
} from "../styles";
import Calendar from "../../icons/Calendar";
import ArrowDownIcon from "../../../../../../public/images/icons/arrow-down.svg";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import ChakraModal from "components/TheComponents/Modal";
import ExchangeModal from "components/games/ExchangeBanner/ExchangeModal";
import { triggerNotification } from "components/TheComponents/Notification";
import { DateTime } from "luxon";
import { ProfileNavEnum, useProfileContext } from "components/public/ProfileLayout/ProfileContext";
import { navIconMap } from "components/public/ProfileLayout/components/ProfileNav";
import { Logout } from "../../icons";
import { useRouter } from "next/navigation";
import { useMediaQuery } from "react-responsive";

const DesktopViewProfileMenu = ({
  profile,
  onOpenLogout,
}: ITheHeaderProfile & {
  onOpenLogout: () => void;
}): React.ReactElement => {
  const router = useRouter();
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const [isShowModal, setIsShowModal] = React.useState(false);
  const [currentMonthIsAugust, setCurrentMonthIsAugust] = React.useState(false);
  const { changeActiveTab } = useProfileContext();

  React.useEffect(() => {
    const currentUTCMonth = DateTime.utc().month;
    setCurrentMonthIsAugust(currentUTCMonth === 8); // August is month 8
  }, []);

  const exchange = () => {
    if (profile) {
      setIsShowModal(true);
    } else {
      triggerNotification({ text: "Please, login for exchage points", type: "error" });
    }
  };

  const ProfileMenuItems = [
    ProfileNavEnum.Main,
    ProfileNavEnum.Connections,
    ProfileNavEnum.Raffles,
    ProfileNavEnum.Shop,
    ProfileNavEnum.Bets,
  ];

  const handleMenuItemClick = useCallback(
    (item: ProfileNavEnum, onClose: () => void) => {
      router.push("/profile");
      changeActiveTab(item);
      onClose();
    },
    [router, changeActiveTab]
  );

  return (
    <>
      <DesktopView>
        <Menu placement="auto" isLazy>
          {({ isOpen }) => (
            <>
              <MenuButton isActive={isOpen} as={BalanceBtn}>
                {!isTablet && "Balance"}
                <BalanceBlock>
                  <Image alt="g-coin" src="/images/coins/g-coin.svg" width={20} height={20} />
                  <ThePointsBalance value={profile?.gPointsBalance} />
                  <div className="icon">
                    <ArrowDownIcon />
                  </div>
                </BalanceBlock>
              </MenuButton>
              <MenuList padding={0} background={"transparent"} zIndex={1001}>
                <MenuListStyled>
                  <div className="title">Your points balance</div>
                  <div className="content">
                    <BalanceTile>
                      <Image alt="w-coin" src="/images/coins/w-coin.svg" width={24} height={24} />
                      <div>
                        <small>W-Points</small>
                        <Text>
                          <ThePointsBalance value={profile?.pointsBalance} isFixed />
                        </Text>
                      </div>
                    </BalanceTile>
                    <BalanceTile>
                      <Image alt="g-coin" src="/images/coins/g-coin.svg" width={24} height={24} />
                      <div>
                        <small>G-Points</small>
                        <Text>
                          <ThePointsBalance value={profile?.gPointsBalance} />
                        </Text>
                      </div>
                    </BalanceTile>

                    <ExchangeBtn onClick={exchange}>
                      <Image alt="g-coin" src="/images/icons/exchange.svg" width={24} height={24} />
                      <span>Exchange Points</span>
                    </ExchangeBtn>
                  </div>
                </MenuListStyled>
              </MenuList>
            </>
          )}
        </Menu>
        {!isMobile && (
          <CalendarLink href="/advent-calendar">
            <Calendar fill="#F2C94C" />
            <span>Calendar</span>
          </CalendarLink>
        )}
        <Menu placement="auto" isLazy>
          {({ isOpen, onClose }) => (
            <>
              <MenuButton isActive={isOpen} as={ProfileBtn}>
                <div>
                  <Image
                    alt="profile"
                    src={profile?.profileImageUrl ?? ""}
                    width={24}
                    height={24}
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <ProfileDisplayName>{profile?.displayName}</ProfileDisplayName>
                <div className="icon">
                  <ArrowDownIcon />
                </div>
              </MenuButton>

              <MenuList padding={0} background={"transparent"}>
                <ProfileListStyled>
                  {ProfileMenuItems.map((item, index) => (
                    <ProfileListItem key={index} onClick={() => handleMenuItemClick(item, onClose)}>
                      {navIconMap[item]}
                      {item}
                    </ProfileListItem>
                  ))}

                  <ProfileListItem onClick={onOpenLogout}>
                    <Logout />
                    Log Out
                  </ProfileListItem>
                </ProfileListStyled>
              </MenuList>
            </>
          )}
        </Menu>
      </DesktopView>
      
      <ChakraModal
        content={
          <ExchangeModal
            onClose={() => {
              setIsShowModal(false);
            }}
          />
        }
        isOpen={isShowModal}
        onClose={() => setIsShowModal(false)}
      />
    </>
  );
};

export default DesktopViewProfileMenu;
