import Image from "next/image";
import React from "react";
import styled, { css } from "styled-components";
import {
  $styles,
  $stylesReset,
  IStyles,
  TheCheckbox,
  TheFieldset,
  TheIcon,
  TheNextScroll,
} from "components";
import { Button, ButtonBlue, DualRingInner, DualRingLoader } from "components/common";
import { BeAGuestDasboard, useAppContext } from "contexts";
import { triggerNotification } from "components/TheComponents/Notification";
import { socket } from "socket";
import { SocketEvent } from "helpers/constants";
import { ExceptionHandlingType } from "types/apiServiceTypes";
import { useDisclosure } from "@chakra-ui/react";
import ChakraModal from "components/TheComponents/Modal";

export interface IBeAGuestComponent {
  id?: string;
  nextScrollHref?: string;
}

const BeAGuestComponent: React.FC<IBeAGuestComponent> = ({
  id,
  nextScrollHref = "#",
}): React.ReactElement => {
  const { isOpen: isFormOpen, onClose: closeForm, onOpen: openForm } = useDisclosure();
  const { loginModalProps, onDiscordRedirect, accessFetch, profile } = useAppContext();
  const { onOpenLogin } = loginModalProps;
  const profileDiscordId = profile?.discordId;
  const [message, setMessage] = React.useState<string>("");
  const [dashboard, setDashboard] = React.useState<BeAGuestDasboard>({
    totalParticipants: 0,
    probability: 0,
  });
  const [haveMicrophone, setHaveMicrophone] = React.useState<boolean>(true);
  const [haveWebcam, setHaveWebcam] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<any>({});

  React.useEffect(() => {
    socket.on(SocketEvent.StreamGuestsDashboardUpdate, (data) => {
      setDashboard(data);
    });

    return () => {
      socket.off(SocketEvent.StreamGuestsDashboardUpdate, () =>
        console.log("Not listening to StreamGuestsDashboardUpdate")
      );
    };
  }, []);

  const onTextChange = React.useMemo(() => {
    return (setValue: Function) => (event: React.ChangeEvent) => {
      event.preventDefault();
      const { target } = event;
      if (target instanceof HTMLInputElement) {
        const { value } = target as HTMLInputElement;
        setValue(value);
      } else if (target instanceof HTMLTextAreaElement) {
        const { value } = target as HTMLTextAreaElement;
        setValue(value);
      }
    };
  }, []);

  const fetchDashboard = React.useCallback(async () => {
    const response = await accessFetch(
      "/pick-a-guest/dashboard",
      undefined,
      ExceptionHandlingType.SILENT
    );
    const data = await response?.json();
    setDashboard(data);
  }, [accessFetch]);

  React.useEffect(() => {
    fetchDashboard(); // Initial data fetch
  }, [fetchDashboard]);

  const onLabelClink = React.useMemo(() => {
    return (checked: boolean, setChecked: Function) => (event: React.MouseEvent) => {
      event.preventDefault();
      setChecked(!checked);
    };
  }, []);

  const onCheckboxChange = React.useMemo(() => {
    return () => (event: React.ChangeEvent) => {
      event.preventDefault();
    };
  }, []);

  const onLinkDiscordAttempt = () => {
    if (profile) {
      onDiscordRedirect();
    } else {
      onOpenLogin();
    }
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (message.length > 3000) {
      const message = "Message is too long";
      newErrors.message = message;
      triggerNotification({ text: message, type: "error" });
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const becomeParticipant = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await accessFetch(
        "/pick-a-guest/participate",
        {
          method: "POST",
          body: JSON.stringify({
            message: message,
            microphone: haveMicrophone,
            camera: haveWebcam,
          }),
        },
        ExceptionHandlingType.AUTOMATIC
      );
      const data = await response?.json();

      if (response && (response.status === 200 || response.status === 201)) {
        triggerNotification({ text: "Request sent!", type: "success" });
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  function formatProbability(probability: number) {
    // Round the probability to 2 decimal places
    const roundedProbability = Math.round(probability * 100) / 100;

    // Check if the rounded probability is less than 0.01
    if (roundedProbability < 0.01) {
      return "< 0.01";
    } else {
      return roundedProbability.toFixed(2);
    }
  }

  return (
    <Box id={id} className="container">
      <Content>
        <Picture>
          <Img
            src="/images/BeAGuestComponent/gool.png"
            width={63}
            height={65}
            alt="gool"
            styles={css`
              position: absolute;
              top: 0;
              left: 50%;

              @media (max-width: 620px) {
                display: none;
              }
            `}
          />
          <Img
            src="/images/BeAGuestComponent/dog.png"
            width={120}
            height={123}
            alt="dog"
            styles={css`
              position: absolute;
              top: 0;
              right: 0;

              @media (max-width: 620px) {
                display: none;
              }
            `}
          />
          <Img
            src="/images/BeAGuestComponent/play_1.png"
            width={600}
            height={600}
            alt="play"
            styles={css`
              position: absolute;
              top: 0;
              right: 0;
              transform: translateY(-15%) translateX(16%);

              @media (max-width: 940px) {
                right: 50%;
                transform: translateY(-25%) translateX(55%);
              }
            `}
          />
          <Img
            src="/images/BeAGuestComponent/hat.png"
            width={100}
            height={100}
            alt="hat"
            styles={css`
              position: absolute;
              top: 14%;
              left: 58.5%;

              @media (max-width: 940px) {
                top: 0;
                left: 20%;
              }

              @media (max-width: 620px) {
                display: none;
              }
            `}
          />
          <Img
            src="/images/BeAGuestComponent/hands.png"
            width={250}
            height={140}
            alt="hands"
            styles={css`
              position: absolute;
              top: 45%;
              right: 25%;

              @media (max-width: 940px) {
                top: 38%;
                right: 40%;
              }

              @media (max-width: 620px) {
                display: none;
              }
            `}
          />
          <Img
            src="/images/BeAGuestComponent/bonus.png"
            width={100}
            height={120}
            alt="bonus"
            styles={css`
              position: absolute;
              top: 58%;
              right: 8%;
              transform: rotate(45deg);

              @media (max-width: 940px) {
                top: 50%;
                right: 25%;
              }

              @media (max-width: 620px) {
                display: none;
              }
            `}
          />
          <Img
            src="/images/BeAGuestComponent/ball.png"
            width={100}
            height={100}
            alt="ball"
            styles={css`
              position: absolute;
              bottom: 0;
              right: 0;
            `}
          />
        </Picture>
        <BoxH1>
          Want to be a Guest of
          <br />
          <span data-lg-font>
            next <span data-blue>WGTV </span>video?
          </span>
        </BoxH1>
        <BoxP1>
          Once a week one of the WGTV subscribers will get a chance to spend site balance on youtube
          video.
        </BoxP1>
        <BoxTP>
          <span data-row>
            <Img
              src="/images/BeAGuestComponent/paper.svg"
              priority
              width={32}
              height={32}
              alt="paper"
            />
            <span data-column>
              <span>Total assigns</span>
              <span data-value>{dashboard?.totalParticipants}</span>
            </span>
          </span>
          <span data-row>
            <Img
              src="/images/BeAGuestComponent/dice.svg"
              priority
              width={32}
              height={32}
              alt="paper"
            />
            <span data-column>
              <span>Probability</span>
              <span data-value>
                {dashboard?.totalParticipants > 0
                  ? formatProbability(dashboard.probability)
                  : "0.00"}
                %
              </span>
            </span>
          </span>
        </BoxTP>
        <SubmitBtn onClick={() => openForm()}>I want to be a guest!</SubmitBtn>
      </Content>
      <ChakraModal
        isOpen={isFormOpen}
        onClose={closeForm}
        content={
          <BoxFormDecoration>
            <BoxForm>
              <BoxH2>Get invited for WGTV video!</BoxH2>
              <BoxP2>You will be automatically added to a roll !</BoxP2>
              {profileDiscordId ? (
                <TheFieldset
                  disabled={true}
                  valid={!errors.discordId}
                  title="Discord Name"
                  styles={css`
                    margin-bottom: 16px;
                    background-image: url("/images/icons/discord-purple.png");
                    background-repeat: no-repeat;
                    background-size: 20px 15px;
                    background-position: 3% center;
                    opacity: 0.9;
                    padding-left: 35px;
                  `}
                >
                  <input
                    type="text"
                    placeholder={errors.discordId ? errors.discordId : "Discord Id"}
                    value={profile?.discordUsername || ""}
                    onChange={() => {}}
                  />
                </TheFieldset>
              ) : (
                <Button
                  styles={css`
                    margin: 0 2px 16px;
                    border: 1px solid #3b8be9;
                    background: #101622;
                    height: 50px;
                    padding: 10px 22px;
                  `}
                  height={50}
                  isDark
                  onClick={onLinkDiscordAttempt}
                >
                  <ButtonBlue isDark>
                    <Image
                      src="/images/icons/discord-purple.png"
                      height={15}
                      width={19}
                      alt="image"
                    />
                    Connect
                  </ButtonBlue>
                </Button>
              )}
              <TheFieldset
                empty={!message}
                valid={!errors.message}
                disabled={!profileDiscordId}
                title="Message"
                styles={css`
                  margin-bottom: 16px;
                `}
              >
                <textarea
                  placeholder={errors.message ? errors.message : "Message (Optional)"}
                  value={message}
                  onChange={onTextChange(setMessage)}
                />
              </TheFieldset>
              <BoxP2>Does not effect your chance*</BoxP2>
              <BoxP2>
                <Label onClick={onLabelClink(haveMicrophone, setHaveMicrophone)}>
                  <TheCheckbox
                    preset="dark-blue"
                    checked={haveMicrophone}
                    onChange={onCheckboxChange()}
                  />
                  <span data-checked={haveMicrophone}>I have a good microphone</span>
                </Label>
              </BoxP2>
              <BoxP2>
                <Label onClick={onLabelClink(haveWebcam, setHaveWebcam)}>
                  <TheCheckbox
                    preset="dark-blue"
                    checked={haveWebcam}
                    onChange={onCheckboxChange()}
                  />
                  <span data-checked={haveWebcam}>I have a nice webcam</span>
                </Label>
              </BoxP2>
              <Button
                disabled={!profileDiscordId}
                isLoading={isLoading}
                onClick={(event: React.MouseEvent) => {
                  becomeParticipant(event);
                }}
                style={{ height: 50, cursor: "pointer", width: "100%" }}
              >
                <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
                  {isLoading ? (
                    <DualRingLoader smallRing>
                      <DualRingInner smallRing />
                    </DualRingLoader>
                  ) : (
                    "Send Request"
                  )}
                </ButtonBlue>
              </Button>
            </BoxForm>
          </BoxFormDecoration>
        }
      />
    </Box>
  );
};

export default BeAGuestComponent;

const Box = styled.div`
  padding: 30px 16px;
`;

const Content = styled.div`
  ${$stylesReset}
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: var(--global-bg-dark-1);
  padding: 32px 50% 32px 32px;
  gap: 24px;
  /* background: url("/images/BeAGuestComponent/bg-light.png") no-repeat center center; */
  /* background-size: container; */
  /* background-position: center right; */

  &::after {
    content: "";
    z-index: 1;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: url("/images/BeAGuestComponent/cover-light.png") no-repeat center center;
    background-size: cover;
    pointer-events: none;
  }

  @media (max-width: 940px) {
    padding: 50% 32px 32px 32px;
  }
`;

const Picture = styled.div`
  z-index: 3;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url("/images/BeAGuestComponent/cover-dark.png") no-repeat center center;
  background-size: cover;
  pointer-events: none;

  @media (max-width: 940px) {
    height: 50%;
  }
`;

const Img = styled(Image)<IStyles>`
  ${$styles}
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const BoxH1 = styled.div<IStyles>`
  z-index: 2;
  position: relative;
  color: var(--daylight-color);
  font-family: var(--font-family-exo);
  font-size: 24px;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;

  & [data-lg-font] {
    font-size: 40px;
  }

  & [data-blue] {
    color: rgba(59, 139, 233, 1);
  }

  ${$styles}

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

const BoxP1 = styled.div<IStyles>`
  z-index: 2;
  position: relative;
  color: var(--daylight-color);
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  ${$styles}

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const BoxTP = styled.div<IStyles>`
  display: flex;
  gap: 8px;

  & [data-row] {
    flex: 1;
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    gap: 8px;
    padding: 16px;
    height: 76px;
    border-radius: 12px;
    background: linear-gradient(
      180deg,
      rgba(233, 244, 255, 0.05) -31.64%,
      rgba(255, 255, 255, 0) 130.86%
    );
  }

  & [data-column] {
    display: inline-flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    color: rgba(200, 215, 245, 0.5);
    font-family: var(--font-family-golos);
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    text-transform: uppercase;
  }

  & [data-value] {
    color: var(--daylight-color);
    font-family: var(--font-family-exo);
    font-size: 24px;
    font-style: italic;
    font-weight: 700;
    line-height: 130%;
  }

  ${$styles}

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const SubmitBtn = styled.div<IStyles>`
  z-index: 2;
  position: relative;
  display: flex;
  width: 100%;
  height: 46px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: var(--pure-sky);
  box-shadow: 0px 2px 0px 0px #055ba0;
  color: #131635;
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    border: 1px solid #8dddff;
  }
`;

const BoxFormDecoration = styled.div<IStyles>`
  /* display: none; */
  box-sizing: border-box;
  position: relative;

  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    right: -135px;
    top: 250px;
    width: 199px;
    height: 212px;
    background-clip: border-box;
    background-image: url("/images/BeAGuestComponent/video.png");
    background-position: center;
    background-repeat: no-repeat;
    animation: float 6s ease-in-out infinite;
  }

  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    left: -80px;
    top: 20px;
    width: 118px;
    height: 121px;
    background-clip: border-box;
    background-image: url("/images/BeAGuestComponent/bonus.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: calc(118px * 0.9) calc(121px * 0.9);
    animation: float 4s ease-in-out infinite;
  }

  @media (max-width: 666px) {
    &::before {
      display: none;
    }
  }
  @media (max-width: 550px) {
    &::after {
      display: none;
    }
  }

  ${$styles}
`;

const BoxForm = styled.form<IStyles>`
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  width: 390px;
  /* height: 571px; */
  padding: 40px;

  // background
  background: radial-gradient(
      152.76% 130.7% at 50% 0%,
      rgba(101, 101, 101, 0.05) 0%,
      rgba(101, 101, 101, 0) 100%
    ),
    #151c2c;

  // border
  border: 1px solid rgba(210, 234, 255, 0.1);
  border-radius: 8px;

  ${$styles}
`;

const BoxH2 = styled.div<IStyles>`
  color: #d2eaff;
  font-family: "Roboto";
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 23px;
  text-transform: uppercase;

  ${$styles}
`;

const BoxP2 = styled.div<IStyles>`
  box-sizing: border-box;
  margin: 16px 0;

  // text
  color: rgba(210, 234, 255, 0.5);
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;

  & [data-checked="true"] {
    color: #d2eaff;
  }

  ${$styles}
`;

const Label = styled.label<IStyles>`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  cursor: pointer;

  ${$styles}
`;
