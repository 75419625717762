import styled from "styled-components";

export const SlotSection = styled.div`
  width: 100%;
  padding: 32px 0px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    background-color: #101622;
    padding: 16px 0;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .title-wrapper {
    align-items: center;
    display: flex;
    gap: 16px;

    .icon {
      background-color: transparent;
      color: #d2eaff;
      border-radius: 50px;
      overflow: visible;
      box-shadow: 0px 3px 6px 0px #0b0e23, 0px 0px 8px 0px #3a71fe75, 0px 0px 32px 0px #2871ff80;
    }
  }

  .title {
    margin: 0;
    font-size: 20px;
    font-style: italic;
    color: #d2eaff;
  }
`;

export const ViewAll = styled.button`
  border: 1px solid #24274680;
  background: #24274680;
  color: #d2eaff;
  padding: 12px 32px;
  border-radius: 8px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const SlotGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 16px;
`;

export const SlotCard = styled.div<{ colorPreset: string[] }>`
  --gradientFrom: ${({ colorPreset }) => colorPreset?.[0]};
  --gradientTo: ${({ colorPreset }) => colorPreset?.[1]};
  position: relative;

  user-select: none;

  transition: transform 0.1s ease-in-out;
  border: 1px #1e213a solid;
  aspect-ratio: 3/4;

  border-radius: 8px;
  overflow: hidden;

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding: 8px;
    z-index: 2;
  }

  .name {
    font-weight: 900;
    text-align: center;
    font-size: 24px;
    text-shadow: 0px 1px 2px #000000b0;
  }

  .provider {
    font-size: 15px;
    font-weight: 500;
    text-shadow: 0px 1px 2px #000000b0;
  }

  &::after {
    position: absolute;
    inset: 0 -10px -50px;
    content: "";

    background: linear-gradient(
      181.56deg,
      rgba(7, 244, 187, 0) 36.21%,
      var(--gradientFrom) 63.81%,
      var(--gradientTo) 78.74%
    );

    transition: all linear 0.2s;
  }

  .img-name {
    z-index: 2;
    transform: scale(0.95);
    transition: transform 0.2s ease-in-out;
  }
  .img-name {
    transform: translateY(-10px);
  }
  .img-icon,
  .img-bg {
    transition: transform 0.3s linear;
  }

  .img-icon {
    transform: scale(1);
  }

  .name {
    transform: translateY(20px);
    transition: transform 0.2s linear;
  }

  .provider {
    opacity: 0;
    transform: translateY(30px);
    transition: transform 0.2s linear;
  }

  &:active {
    transform: scale(1.05);
  }

  &:hover {
    cursor: pointer;
    .img-icon {
      transform: scale(1.08);
    }

    .img-name {
      transform: scale(1.01) translateY(0px);
    }

    .provider {
      opacity: 1;
      transform: translateY(0);
    }

    .name {
      transform: translateY(0);
    }

    &::after {
      inset: -10px;
    }
  }
`;
